import React, { createContext, useState } from 'react';

const defaultState = {
  isOpenContactForm: false,
  setOpenContactForm: () => { },
  isOpenEstimateForm: false,
  setOpenEstimateForm: () => { },
  isOpenMenu: false,
  setOpenMenu: () => { }
}
export const LayoutContext = createContext(defaultState)

export const LayoutProvider = ({ children }) => {
  const [isOpenContactForm, setOpenContactForm] = useState(false)
  const [isOpenEstimateForm, setOpenEstimateForm] = useState(false)
  const [isOpenMenu, setOpenMenu] = useState(false)

  return (
    <LayoutContext.Provider value={{
      isOpenContactForm,
      setOpenContactForm,
      isOpenEstimateForm,
      setOpenEstimateForm,
      isOpenMenu,
      setOpenMenu
    }}>
      {children}
    </LayoutContext.Provider>
  )
}
